import getConfig from 'next/config';
import { TrackingEnvironmentEnum } from '~/shared/components';

type PublicConfigType = {
    API_URL: string;
    BACKEND_API_URL: string;
    RELEWISE_ENVIRONMENT_ID: string;
    RELEWISE_SEARCH_API_KEY: string;
    RELEWISE_API_URL: string;
    TRACKING_ENVIRONMENT?: TrackingEnvironmentEnum;
    KOMPAN_BASE_URL: string;
    MYKOMPAN_BASE_URL: string;
    MYKOMPAN_AUTH_CLIENT_ID: string;
    MYKOMPAN_AUTH_CLIENT_NAME: string;
    MYKOMPAN_USER_SIGNUP_SIGNIN_FLOW: string;
    MYKOMPAN_USER_OTP_SIGNIN_FLOW: string;
    MYKOMPAN_USER_RESET_PASSWORD_FLOW: string;
    MYKOMPAN_USER_SIGNIN_KOMPAN_AAD_FLOW: string;
    AZ_GET_ACTIVITY_IMAGES: string;
    MEDIA_BASE_URL: string;
    DEPRECATED_MEDIA_API_URL: string;
    MYKOMPAN_MEDIA_BASE_URL: string;
};

export const publicRuntimeConfig = () => {
    const { publicRuntimeConfig } = getConfig() as { publicRuntimeConfig: PublicConfigType };
    return publicRuntimeConfig;
};
